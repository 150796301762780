export const checkAuth = () => {
  let assisData = localStorage.getItem('assisData');
  if (assisData === null) {
    return false;
  }
  let user = JSON.parse(assisData);
  let lastLogDate = user.date;

  const date1 = new Date();
  const date2 = new Date(lastLogDate);
  const diffTime = Math.abs(date2 - date1);

  return diffTime > 86400 * 1000 ? false: true
}

export const getToken = () =>
  JSON.parse(localStorage.getItem('assisData')) != null
    ? JSON.parse(localStorage.getItem('assisData')).token
    : null;

export const logout = () =>
  localStorage.clear() === null ? false : true;