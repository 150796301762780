import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import axios from 'axios';
import { getToken } from 'actions/sessionActions';

import { Page } from 'components';

import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import ClipLoader from 'react-spinners/ClipLoader';

import { Bar, Line } from 'react-chartjs-2';

import Collapsible from 'react-collapsible';


import './Relatorio.css'

import Form from 'react-bootstrap/Form';

import moment from 'moment';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { async } from 'validate.js';
import Viewer from './components/Viewer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 6, 3)
  },
  printarea: {},
  saveButton: {
    marginLeft: 10
  },
  header: {
    paddingTop: '10px',
    paddingLeft: '10px',
    marginBottom: '15px'
  },
  order: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const Relatorio = (props) => {
  const classes = useStyles();

  let configs = JSON.parse(localStorage.getItem("assisData"))
  let formatter = new Intl.NumberFormat(configs.format, {
    style: 'currency',
    currency: configs.currency,
  });


  const [data, setData] = useState([]);

  let [loading, setLoading] = useState(false);

  const style = {
    position: 'fixed',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };



  useEffect(() => {
    setLoading(true);
    (async () => {

      let currMonth = moment().month()
      let d = [];

      let allData = [];
      for (let index = 0; index < 5; index++) {
        let m = moment().month(currMonth - index)
        if (m.year() < 2023) {
          continue
        }        
        //http://cloud.motomco.com.br   GET
        let { data } = await axios.post(`/log/password/assistencia?date=${m.year()}-${m.month()}&email=${configs.email.substring(configs.email.indexOf("@"))}&hp=${configs.emailhp}`)

        allData.push({ "values": m, "data": data });
      }

      setData(allData);
      setLoading(false);
    })();

  }, [])


  return (
    <Page className={classes.root} title="Equipamentos">


      <Page>
        <div><h5>Configurações</h5></div>

        <div >
          <TextField
            style={{ margin: 10, minWidth: 300 }}
            className={classes.textField}
            aria-readonly="true"
            autoComplete="off"
            label="Email"
            name="email"
            value={configs.email}
            disabled={true}
            variant="outlined"
          />


          <TextField
            style={{ margin: 10, minWidth: 300 }}
            className={classes.textField}
            aria-readonly="true"
            autoComplete="off"
            label="Email Help Desk"
            name="email_help_desk"
            value={configs.emailhp}
            disabled={true}
            variant="outlined"
          />

        </div>


        <div>
          <TextField
            style={{ margin: 10 }}
            className={classes.textField}
            aria-readonly="true"
            autoComplete="off"
            label="Valor Chave assistência"
            name="vassist"
            value={formatter.format(configs.valor)}
            disabled={true}
            variant="outlined"
          />


          <TextField
            style={{ margin: 10 }}
            className={classes.textField}
            aria-readonly="true"
            autoComplete="off"
            label="Valor chave Help Desk"
            name="v_help"
            value={formatter.format(configs.valorhp)}
            disabled={true}
            variant="outlined"
          />

          <TextField
            style={{ margin: 10 }}
            className={classes.textField}
            aria-readonly="true"
            autoComplete="off"
            label="Valor chave Loc Solution"
            name="V-loc"
            value={formatter.format(configs.valorloc)}
            disabled={true}
            variant="outlined"
          />

        </div>


      </Page>

      {data.map(value => (
        <Viewer data={value}></Viewer>
      ))}


      <div style={style}>
        <ClipLoader color={'#09D052'} loading={loading} size={200} />
      </div>


    </Page>
  );
};

export default Relatorio;
