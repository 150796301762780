import React from 'react';

import ListGroup from 'react-bootstrap/ListGroup';

import dateformat from 'utils/dateformat';

const ResultsList = props => {

  const { list } = props;

  return (
    <ul as="ol" numbered>
      {list && list.map(data => (
        <li as="li" class="border" style={{
          display: 'list-item', listStylePosition: 'inside'
        }}>
          {data.serialnumber}, {dateformat(data.data, true).replace(',', '')}, {data.name}, {data.email}
        </li>
      ))}

    </ul>
  );
};

export default ResultsList;
