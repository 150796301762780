/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';

import { Label } from 'components';
import { colors } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from '@material-ui/icons/Map';
import Business from '@material-ui/icons/Business';
import Inbox from '@material-ui/icons/Inbox';
import Assignment from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import PasswordIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Notifications from '@material-ui/icons/Send';

export default [
  {
    pages: [
      {
        title: 'Relátorio',
        href: '/relatorio',
        icon: Assignment
      },
      {
        title: 'Sair',
        href: '/logout',
        icon: LogoutIcon,
      }
    ]
  }
];
