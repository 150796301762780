import React, { useState } from 'react';

import Collapsible from 'react-collapsible';

// import './RelatorioMaster.css'


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ResultsList from './ResultsList';
import { Button } from '@material-ui/core';


const Viewer = props => {

  const { data } = props



  let configs = JSON.parse(localStorage.getItem("assisData"))

  let formatter = new Intl.NumberFormat(configs.format, {
    style: 'currency',
    currency: configs.currency,
  });



  return (
    <div>
      <h3 style={{ textTransform: 'capitalize' }}>{Intl.DateTimeFormat('pt', { month: 'long' }).format(new Date(data.values.year(), data.values.month()))}</h3>
      <Button style={{ margin: 10 }} variant='outlined' href={`/log/password/assistencia/download?date=${data.values.year()}-${data.values.month()}&email=${configs.email.substring(configs.email.indexOf("@"))}&hp=${configs.emailhp}`}
      >Download
      </Button>
      <div >
        {(() => {
          let somaTodasAsChaves = data.data.allData.length;
          let somaChavesCobradas = data.data.filteredData.length;
          let somaChavesHelpDesk = data.data.assistData.length;
          let somaChavesReentrada = data.data.reentranceAssisData.length;
          let somaChavesLocSolution = data.data.locData.length;
          let somaChavesGarantia = data.data.logsInGuarante.length;

          let serialNumbers = data.data.logsInGuarante.map(i => i.serialnumber);
          serialNumbers = [...new Set(serialNumbers)];

          let nEquipments = serialNumbers.length;

          let somaChaves = somaChavesCobradas - somaChavesHelpDesk - somaChavesLocSolution;
          let somachavesExtraValue = 0;
          if (configs.desconto) {    
          if (somaChaves > 300) {
            somachavesExtraValue = somaChaves - 300;
            somaChaves = 300;
          }
        }
          // let sum6 = 0;
          // if (isentData[year] && isentData[year][month]) {
          //   Object.keys(isentData[year][month]).forEach(i => sum6 += isentData[year][month][i].length)
          // }
          return <div>
            <Collapsible trigger={
              <>
                <span>Total de chaves (mês): {somaTodasAsChaves} </span>
                <ExpandMoreIcon style={{ float: 'right', position: 'relative' }} />
              </>
            } transitionTime={250} style={{ background: '#FF0000' }}>
              <ResultsList
                list={data.data.allData}
              />
            </Collapsible>

            <Collapsible trigger={
              <>
                <span>Chaves contabilizadas (mês): {somaChavesCobradas} </span>
                <ExpandMoreIcon style={{ float: 'right', position: 'relative' }} />
              </>
            } transitionTime={250} style={{ background: '#FF0000' }}>
              <ResultsList
                list={data.data.filteredData}
              />
            </Collapsible>


            <Collapsible trigger={
              <>
                <span>Chaves help desk (mês): {somaChavesHelpDesk}
                </span>
                <ExpandMoreIcon style={{ float: 'right', position: 'relative' }} />
              </>
            } transitionTime={250} style={{ background: '#FF0000' }}>
              <ResultsList
                list={data.data.assistData}
              />
            </Collapsible>


            <Collapsible trigger={
              <>
                <span>Chaves Loc Solution (mês): {somaChavesLocSolution}
                </span>
                <ExpandMoreIcon style={{ float: 'right', position: 'relative' }} />
              </>
            } transitionTime={250} style={{ background: '#FF0000' }}>
              <ResultsList
                list={data.data.locData}
              />
            </Collapsible>


            { somaChavesReentrada > 0 ? <Collapsible trigger={
              <>
                <span>Chaves geradas pelo help desk reentrada fisíca(mês): {somaChavesReentrada}
                </span>
                <ExpandMoreIcon style={{ float: 'right', position: 'relative' }} />
              </>
            } transitionTime={250} style={{ background: '#FF0000' }}>
              <ResultsList
                list={data.data.reentranceAssisData}
              />
            </Collapsible> : null}


            {somaChavesGarantia > 0 ? <Collapsible trigger={
              <>
                <span>Chaves geradas em garantia 6 meses(mês): {somaChavesGarantia}, Número de equipamentos: {nEquipments}
                </span>
                <ExpandMoreIcon style={{ float: 'right', position: 'relative' }} />
              </>
            } transitionTime={250} style={{ background: '#FF0000' }}>
              <ResultsList
                list={data.data.logsInGuarante}
              />
            </Collapsible>: null}


            <div class="row border" style={{ marginTop: 15, marginBottom: 15, marginLeft: 5, marginRight: 5 }}>
              <>
                <div class="col border">Valor chaves assistência ( {( configs.desconto && somaChaves < 300) ? somaChaves : ` ${somaChaves} X R$ ${formatter.format(configs.desconto ? (configs.valor - configs.desconto) : configs.valor)}`} )</div>
                <div class="col border">{ (configs.desconto && somaChaves < 300) ? formatter.format(30000) : formatter.format(somaChaves * (configs.desconto ? (configs.valor - configs.desconto) : configs.valor))}</div>
                <div class="w-100"></div>
              </>

              {somachavesExtraValue != 0 ? <>
                <div class="col border">Valor chaves assistência *Sem desconto ( {somachavesExtraValue} X R$ {formatter.format(configs.valor)} )</div>
                <div class="col border">{formatter.format(somachavesExtraValue * configs.valor)}</div>
                <div class="w-100"></div>
              </> : null}

              {somaChavesHelpDesk != 0 ? <>
                <div class="col border">Valor chaves help desk ( {somaChavesHelpDesk} X R$ {formatter.format(configs.valorhp)} )</div>
                <div class="col border">{formatter.format(somaChavesHelpDesk * configs.valorhp)}</div>
                <div class="w-100"></div>
              </> : null}

              {somaChavesLocSolution != 0 ? <>
                <div class="col border">Valor chaves Loc Solution ( {somaChavesLocSolution} X R$ {formatter.format(configs.valorloc)} )</div>
                <div class="col border">{formatter.format(somaChavesLocSolution * configs.valorloc)}</div>
                <div class="w-100"></div>
              </> : null}

              {somaChavesReentrada != 0 ? <>
                <div class="col border">Valor diferença chaves com reentrada ( {somaChavesReentrada} X R$ {formatter.format(configs.valor - configs.valorhp)} )</div>
                <div class="col border">{formatter.format(somaChavesReentrada * (configs.valor - configs.valorhp))}</div>
                <div class="w-100"></div>
              </> : null}

              <div class="col border">Total</div>
              <div class="col border">{formatter.format((somaChaves < 300  && configs.desconto ? 30000 : somaChaves * (configs.desconto ? (configs.valor - configs.desconto) : configs.valor)) + (somachavesExtraValue * configs.valor) + (somaChavesHelpDesk * configs.valorhp) + (somaChavesLocSolution * configs.valorloc) + (somaChavesReentrada * (configs.valor - configs.valorhp)))}</div>
              <div class="w-100"></div>
            </div>


          </div>
        })()}
      </div>

    </div>

  );
};

export default Viewer;
