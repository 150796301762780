/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

import { checkAuth, logout } from './actions/sessionActions';

import Relatorio from 'views/Relatorio';

const routes = [
  {
    path: '/',
    exact: true,
    render: () =>
      checkAuth() ? <Redirect to="/relatorio" /> : <Redirect to="/auth/login" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/relatorio',
        exact: true,
        render: (props) =>
          checkAuth() ? <Relatorio{...props} /> : <Redirect to="/auth/login" />
      },
      {
        path: '/logout',
        exact: true,
        render: (props) =>
          logout() ? <Redirect to="/auth/login" /> : <Redirect to="/" />
      }
    ]
  }
];

export default routes;
