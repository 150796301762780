import { useEffect } from 'react';

import useRouter from 'utils/useRouter';

const ScrollReset = () => {
  const router = useRouter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export default ScrollReset;
